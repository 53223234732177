import markdownit from 'markdown-it';
import { markdownTablesPlugin } from './tables';

// Note that ProseMirror will call the '.parse(content, {})' method (not '.render(content)').

export function getMarkdownTokenizer() {
  return markdownit('default', {
    html: false,
  })
    .disable('image')
    .disable('strikethrough')
    .use(markdownTablesPlugin);
}
