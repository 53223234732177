import OrderedMap from 'orderedmap';
import { schema as defaultMarkdownSchema } from 'prosemirror-markdown';
import { Schema } from 'prosemirror-model';
import { tableNodes as getTableNodes } from 'prosemirror-tables';
import { normalizeLink } from './normalizeLink';

const tableNodes = getTableNodes({ cellContent: 'text*', tableGroup: 'block', cellAttributes: {} });
const markdownNodes = OrderedMap.from(defaultMarkdownSchema.spec.nodes);
const heading = markdownNodes.get('heading') ?? {};
heading.content = 'text*';
const nodes = markdownNodes.remove('image').append({ ...tableNodes }).update('heading', heading);
const markdownMarks = OrderedMap.from(defaultMarkdownSchema.spec.marks || {});
const marks = markdownMarks.update('link',
  {
    attrs: {
      href: {},
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs(dom) {
          return {
            href: dom instanceof Element ? dom.getAttribute('href') : '',
          };
        },
      },
    ],
    // Note, this doesn't affect the stored content, it only specifies how it's rendered in the editor.
    // 'normalizeLink' may return '', in which case we leave off the href.
    toDOM(node) {
      const normalizedLink = normalizeLink(node.attrs.href);

      return normalizedLink ? [
        'a',
        {
          href: normalizedLink,
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      ] : [
        'a', {},
      ];
    },
  },
);

export const schema = new Schema({ nodes, marks });
