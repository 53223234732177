// Adapted from rich-markdown-editor

import { defaultMarkdownSerializer, MarkdownSerializer } from 'prosemirror-markdown';

// The 'state' in these functions is a MarkdownSerializerState,
// which doesn't have a 'headerBuffer' property. It was added by
// rich-markdown-editor as a work-around for the Markdown header row.
// TODO: Convert this to TS (extend the MarkdownSerializerState class? or add a table_head node?)

const defaultNodes = defaultMarkdownSerializer.nodes;
const tableNodes = {
  table(state, node) {
    state.renderContent(node);
    state.closeBlock(node);
  },
  table_cell(state, node) {
    state.text(node.textContent);
    state.write(' | ');
  },
  table_header(state, node) {
    state.text(node.textContent);
    state.write(' | ');

    if (!state.headerBuffer) {
      state.headerBuffer = '|--';
    } else {
      state.headerBuffer += '--|--';
    }
  },
  table_row(state, node) {
    if (state.headerBuffer) {
      state.write(state.headerBuffer + '--|\n');
      state.headerBuffer = undefined;
    }
    state.write('| ');
    state.renderInline(node);
    state.write('\n');
  },
};
const nodes = Object.assign(tableNodes, defaultNodes);

const defaultMarks = defaultMarkdownSerializer.marks;

export function getMarkdownSerializer() {
  return new MarkdownSerializer(nodes, defaultMarks);
}
