// Adapted from https://github.com/hubgit/react-prosemirror/blob/master/react-prosemirror-config-default/src/keys.js

import { keymap } from 'prosemirror-keymap';
import { undoInputRule } from 'prosemirror-inputrules';
import { undo, redo } from 'prosemirror-history';
import { Schema } from 'prosemirror-model';
import { wrapInList, splitListItem, liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { EditorState, Transaction } from 'prosemirror-state';
import { goToNextCell } from 'prosemirror-tables';
import { EditorView } from 'prosemirror-view';
import {
  baseKeymap,
  toggleMark,
  wrapIn,
  setBlockType,
  chainCommands,
  // exitCode,
  joinUp,
  joinDown,
  // lift,
  selectParentNode,
} from 'prosemirror-commands';

// const insertBreak = (schema: Schema) => (state: EditorState, dispatch: (tr: Transaction) => void) => {
//   const br = schema.nodes.hard_break.create();
//   dispatch(state.tr.replaceSelectionWith(br).scrollIntoView());

//   return true;
// };

// const insertRule = (schema: Schema) => (state: EditorState, dispatch: (tr: Transaction) => void) => {
//   const hr = schema.nodes.horizontal_rule.create();
//   dispatch(state.tr.replaceSelectionWith(hr).scrollIntoView());

//   return true;
// };

export const getKeyCommands = (schema: Schema) => {
  const keys: { [key: string]: (p1: EditorState, p2?: (tr: Transaction) => void, p3?: EditorView) => boolean } = {
    'Mod-z': undo,
    'Shift-Mod-z': redo,
    'Backspace': undoInputRule,
    'Mod-y': redo,
    'Alt-ArrowUp': joinUp,
    'Alt-ArrowDown': joinDown,
    // 'Mod-BracketLeft': lift,
    'Escape': selectParentNode,
    'Mod-b': toggleMark(schema.marks.strong),
    'Mod-i': toggleMark(schema.marks.em),
    'Mod-u': toggleMark(schema.marks.underline),
    'Mod-`': toggleMark(schema.marks.code),
    'Shift-Mod-7': wrapInList(schema.nodes.ordered_list),
    'Shift-Mod-8': wrapInList(schema.nodes.bullet_list),
    'Ctrl->': wrapIn(schema.nodes.blockquote),
    // 'Mod-Enter': chainCommands(exitCode, insertBreak(schema)),
    // 'Shift-Enter': chainCommands(exitCode, insertBreak(schema)),
    // 'Ctrl-Enter': chainCommands(exitCode, insertBreak(schema)),
    'Enter': splitListItem(schema.nodes.list_item),
    'Mod-[': liftListItem(schema.nodes.list_item),
    'Mod-]': sinkListItem(schema.nodes.list_item),
    'Shift-Ctrl-0': setBlockType(schema.nodes.paragraph),
    'Shift-Ctrl-\\': setBlockType(schema.nodes.code_block),
    'Shift-Ctrl-1': setBlockType(schema.nodes.heading, { level: 1 }),
    'Shift-Ctrl-2': setBlockType(schema.nodes.heading, { level: 2 }),
    'Shift-Ctrl-3': setBlockType(schema.nodes.heading, { level: 3 }),
    'Shift-Ctrl-4': setBlockType(schema.nodes.heading, { level: 4 }),
    'Shift-Ctrl-5': setBlockType(schema.nodes.heading, { level: 5 }),
    'Shift-Ctrl-6': setBlockType(schema.nodes.heading, { level: 6 }),
    // 'Mod-_': insertRule(schema), // ie, an <hr/>
    'Tab': goToNextCell(1),
    'Shift-Tab': goToNextCell(-1),
  };

  Object.keys(baseKeymap).forEach(key => {
    if (keys[key]) {
      keys[key] = chainCommands(keys[key], baseKeymap[key]);
    } else {
      keys[key] = baseKeymap[key];
    }
  });

  return keymap(keys);
};
