import { EditorView } from 'prosemirror-view';
import React from 'react';
import ExistingLinkDialog from './ExistingLinkDialog';
import { NewLinkDialog } from './NewLinkDialog';

export interface LinkEditorProps {
  container: HTMLDivElement | null;
  isAddingNewLink: boolean;
  onCancelAddNewLink: () => void;
  view: EditorView;
}

export function LinkEditor({ container, isAddingNewLink, onCancelAddNewLink, view }: LinkEditorProps) {
  return (
    <>
      { isAddingNewLink ? (
        <NewLinkDialog
          isAddingNewLink={isAddingNewLink}
          onCancelAddNewLink={onCancelAddNewLink}
          view={view}
        />
      ) : (
        <ExistingLinkDialog
          container={container}
          view={view}
        />
      )}
    </>
  );
}
