import { EditorView } from 'prosemirror-view';
import React from 'react';
import { MarkdownMenuItem } from '../lib/markdownMenuItems';
import { MenuButton } from './MenuButton';

export interface MenuButtonGroupProps {
  items: MarkdownMenuItem[];
  onAddLink: () => void;
  view: EditorView;
}

export function MenuButtonGroup({ items, onAddLink, view }: MenuButtonGroupProps) {
  const visibleItems = items.filter((item) => {
    if (item.toHideWhenDisabled) {
      const isDisabled = item.enable && !item.enable(view.state);

      return !isDisabled;
    } else {
      return true;
    }
  });

  return (
    <>
      {visibleItems.map(menuItem => (
        <MenuButton
          key={menuItem.key}
          item={menuItem}
          view={view}
          onAddLink={onAddLink}
        />
      ))}
    </>
  );
}
