// Adapted from: rich-markdown-editor (prosemirror-tables branch) src/lib/markdown/tables.ts

import MarkdownIt from 'markdown-it';
import StateCore from 'markdown-it/lib/rules_core/state_core';

const TOKENS_TO_SKIP = [ 'thead_open', 'thead_close', 'tbody_open', 'tbody_close' ];

export function markdownTablesPlugin(md: MarkdownIt) {
  // insert a new rule after the 'inline' rules are parsed
  md.core.ruler.after('inline', 'tables-pm', (state: StateCore): boolean => {
    const tokens = state.tokens;
    let inside = false;

    for (let i = tokens.length - 1; i > 0; i--) {
      if (inside) {
        tokens[i].level--;
      }

      // filter out incompatible tokens from markdown-it that we don't need
      // in prosemirror. thead/tbody do nothing.
      if (TOKENS_TO_SKIP.includes(tokens[i].type)) {
        inside = !inside;
        tokens.splice(i, 1);
      }
    }

    return true;
  });
}
