import { pick } from 'lodash';
import { defaultMarkdownParser, MarkdownParser, TokenConfig } from 'prosemirror-markdown';
import { Schema } from 'prosemirror-model';
import { getMarkdownTokenizer } from './tokenizer';

// Make sure these tokens match up with what markdown-it produces (check the initialization options on 'markdownit').
const allDefaultMarkdownTokens = defaultMarkdownParser.tokens;
const markdownTokens = pick(allDefaultMarkdownTokens, [
  'blockquote',
  'paragraph',
  'list_item',
  'bullet_list',
  'ordered_list',
  'heading',
  'code_block',
  'fence',
  'hr',
  // 'image', // leave this off intentionally
  'hardbreak',
  'em',
  'strong',
  'link',
  'code_inline',
]);
const tableTokens: { [key: string]: TokenConfig } = {
  table: { block: 'table' },
  tr: { block: 'table_row' },
  th: { block: 'table_header' },
  td: { block: 'table_cell' },
};
const tokens = Object.assign(tableTokens, markdownTokens);

export function getMarkdownParser(schema: Schema) {
  return new MarkdownParser(schema, getMarkdownTokenizer(), tokens);
}
